<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Indexing Page'" :title="'Indexing Page'">
      <div class="list__buttons">
        <div class="list__search">
          <InputSearch
            :search="handleSearch"
            :id="'search'"
            :label="'search'"
            :placeholder="'search...'"
            :setValue="filterData"
          />
        </div></div
    ></ToolsPanel>
    <ContentContainer>
      <div class="list">
        <div class="list__limits">
          <div>
            <div class="list__limit"><b>Indexing limit:</b> {{ indexingLimit }}</div>
            <div class="list__limit"><b>Indexing checking limit:</b> {{ checkIndexingLimit }}</div>
          </div>
        </div>
        <div v-if="statistics" class="list__statistics">
          <div class="list__statistic">
            <b>Count Checked Today:</b> {{ statistics.countCheckedToday }}
          </div>
          <div class="list__statistic">
            <b>Count Checked With Errors Today:</b> {{ statistics.countCheckedWithErrorsToday }}
          </div>
          <div class="list__statistic">
            <b>Count Checked Without Errors Today :</b>
            {{ statistics.countCheckedWithoutErrorsToday }}
          </div>
          <div class="list__statistic">
            <b>Count In Checking Index Queue:</b> {{ statistics.countInCheckingIndexQueue }}
          </div>
          <div class="list__statistic">
            <b>Count In Indexing Queue:</b> {{ statistics.countInIndexingQueue }}
          </div>
          <div class="list__statistic">
            <b>Count Indexed Today:</b> {{ statistics.countIndexedToday }}
          </div>
          <div class="list__statistic">
            <b>Count Indexed With Errors Today:</b> {{ statistics.countIndexedWithErrorsToday }}
          </div>
          <div class="list__statistic">
            <b>Count Indexed Without Errors Today:</b>
            {{ statistics.countIndexedWithoutErrorsToday }}
          </div>
        </div>
        <div class="list__export">
          <MainButton @click.native="handleExport()">Export</MainButton>
        </div>
        <div class="list__toggles">
          <div
            @click="handleChangeSortStatus('all')"
            :class="['list__toggle', handleGetQueryParam('status') === 'all' && 'active']"
          >
            All <span v-if="statistics">({{ statistics.countAll }})</span>
          </div>
          <div
            v-for="status in indexingStatusesList"
            :key="status.id"
            @click="handleChangeSortStatus(status.id)"
            :class="['list__toggle', handleGetQueryParam('status') == status.id && 'active']"
          >
            {{ status.name }} <span>({{ status.count }})</span>
          </div>
        </div>
        <p class="form-create__label">Add to Check indexing (only full url)</p>
        <div class="input-container">
          <InputTextarea
            :set-value="formDataToSend"
            :id="'check'"
            :label="'check'"
            :type="'text'"
            :placeholder="'Add to Check indexing'"
            :max-length="'255'"
            isBordered
          />
        </div>
        <div class="form-create__btn" style="padding-bottom: 30px">
          <MainButton @click.native="handleCheck(formDataToSend.check)">Check</MainButton>
        </div>
        <p class="form-create__label">Add to indexing (only full url)</p>
        <div class="input-container">
          <InputTextarea
            :set-value="formDataToSend"
            :id="'add'"
            :label="'add'"
            :type="'text'"
            :placeholder="'Add to indexing'"
            :max-length="'255'"
            isBordered
          />
        </div>
        <div class="form-create__btn">
          <MainButton @click.native="handleAddToIndex(formDataToSend.add)">Indexing</MainButton>
        </div>
        <div v-if="indexingList.length">
          <CustomTable
            v-if="indexingList.length"
            :data="indexingList"
            :deleted-keys="tableOptions.deletedKeys"
            :rename-columns="tableOptions.renameColumn"
            :custom-order="tableOptions.customOrder"
            :is-action-block-visible="tableOptions.isActionBlockVisible"
            :handleFilter="handleFilter"
            :handleCopy="handleSee"
            :direction="directionSort"
            :order="orderSort"
          >
          </CustomTable>
          <div class="pagination">
            <Pagination
              :maxPage="indexing.lastPage"
              :page="page"
              :dataList="indexing"
              :urlList="'INDEXING_LIST'"
            />
          </div>
        </div>
        <div class="list__no-have" v-else>No Indexing Page found by this parameter.</div>
      </div>
    </ContentContainer>
    <ModalError
      :error-modal="false"
      :handle-close-error-modal="
        () => {
          this.error = {};
        }
      "
    />
    <MainModal v-if="seeModal" :on-close="handleCloseConfirmModal" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">URL: {{ row.url }}</div>
        <div class="list__confirm-text">Status: {{ row.status_id }}</div>
        <div class="list__confirm-text">Indexed: {{ row.indexed ? "yes" : "no" }}</div>
        <div v-if="row.errorContext" class="list__confirm-text">
          Error Message: {{ row.errorContext.message }}
        </div>
        <div class="list__confirm-buttons center">
          <div class="list__confirm-button">
            <MainButton @click.native="handleAddIndex(row.url)" :button-cancel="true"
              >ADD TO INDEX</MainButton
            >
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal v-if="modalSuccess" :on-close="handleCloseSuccessModal">
      <div class="list__success-delete">Completed successfully</div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import ROUTE from "~/constants/routes";
import indexingApi from "~/api/indexing";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";
import Pagination from "~/components/molecules/Pagination.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";
import InputSearch from "~/components/atoms/inputs/InputSearch.vue";

export default {
  name: "IndexingPageList",
  metaInfo: {
    title: "Indexing Page List",
  },
  data() {
    return {
      deleteModal: false,
      row: null,
      seeModal: false,
      modalSuccess: false,
      directionSort: false,
      orderSort: "id",
      indexing: null,
      indexingList: [],
      loadingProcess: false,
      indexingStatusesList: [],
      checkIndexingLimit: 0,
      indexingLimit: 0,
      statistics: null,
      formDataToSend: {
        check: "",
        add: "",
      },
      filterData: {
        status: null,
        search: null,
      },
      tableOptions: {
        deletedKeys: ["null", "errorContext"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "url",
            to: "Url",
          },
          {
            from: "checked_at",
            to: "checked at",
          },
          {
            from: "status_id",
            to: "Status",
          },
          {
            from: "indexed",
            to: "Indexed",
          },
          {
            from: "indexed_at",
            to: "Indexed at",
          },
        ],
        customOrder: [
          "id",
          "url",
          "checked_at",
          "status_id",
          "indexed",
          "indexed_at",
          "errorContext",
          "null",
        ],
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    CustomTable,
    Pagination,
    MainModal,
    ModalError,
    InputTextarea,
    InputSearch,
  },
  mounted() {
    this.setQueryParams();
    this.getStatuses();
    this.getLimit();
    this.getStatistics();
  },
  watch: {
    $route(to, from) {
      this.getList();
    },
  },
  computed: {
    page() {
      return +this.$route.params.page;
    },
    getQueryParameters() {
      const query = this.$route.fullPath.split("?")[1];
      if (query) {
        return `?${query}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    getList() {
      let url = `?page=${this.page}&perPage=100`;
      if (this.orderSort.length) {
        url = url + `&sortField=${this.orderSort}&sortDest=${this.directionSort ? "asc" : "desc"}`;
      }
      if (this.filterData.search != null || this.filterData.status != null) {
        const data = {};
        if (this.filterData.search != null) {
          data.search = this.filterData.search;
        }
        if (this.filterData.status != null) {
          if (this.filterData.status != "all") {
            data.status_id = this.filterData.status;
          }
        }
        this.loadingProcess = true;
        indexingApi
          .getIndexingFilter(url, data)
          .then((res) => {
            this.indexing = res.data.items;
            const list = res.data.items.data;
            for (let i = 0; list.length > i; i++) {
              list[i].status_id = list[i].status.name;
              list[i].checked_at = list[i].checkedAt;
              list[i].indexed_at = list[i].indexedAt;
            }
            this.indexingList = list;
            this.loadingProcess = false;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loadingProcess = false;
          });
      } else {
        this.loadingProcess = true;
        indexingApi
          .getIndexingList(url)
          .then((res) => {
            this.loadingProcess = false;
            if (res.success) {
              this.indexing = res.data[0];
              const list = res.data[0].data;
              for (let i = 0; list.length > i; i++) {
                list[i].status_id = list[i].status.name;
                list[i].checked_at = list[i].checkedAt;
                list[i].indexed_at = list[i].indexedAt;
              }
              this.indexingList = list;
            }
          })
          .catch((error) => {
            this.loadingProcess = false;

            console.log(error);
          });
      }
    },
    handleGetQueryParam(param) {
      if (this.$route.query[param] != undefined) {
        return this.$route.query[param];
      } else {
        return null;
      }
    },
    setQueryParams() {
      if (this.handleGetQueryParam("status")) {
        this.filterData.status = this.handleGetQueryParam("status");
      }
      if (this.handleGetQueryParam("search")) {
        this.filterData.search = this.handleGetQueryParam("search");
      }
      this.getList();
    },
    handleSetQueryParams() {
      const filter = {};
      if (this.filterData.search != null) {
        filter.search = this.filterData.search;
      }
      if (this.filterData.status != null) {
        filter.status = this.filterData.status;
      }
      if (this.page != 1) {
        this.$router.push({
          path: ROUTE.TEMPLATES_LIST.replace(":page", 1),
        });
      }
      this.$router.push({ name: "IndexingList", query: filter });
    },
    handleChangeSortStatus(status) {
      this.filterData.status = status;
      this.handleSetQueryParams();
    },
    handleSearch() {
      this.handleSetQueryParams();
    },
    getStatuses() {
      this.loadingProcess = true;
      indexingApi
        .getIndexingStatuses()
        .then((res) => {
          this.loadingProcess = false;
          if (res.success) {
            this.indexingStatusesList = res.data[0].data;
          }
        })
        .catch((error) => {
          this.loadingProcess = false;

          console.log(error);
        });
    },
    getLimit() {
      this.loadingProcess = true;
      indexingApi
        .getIndexingLimit()
        .then((res) => {
          this.loadingProcess = false;
          if (res.success) {
            this.indexingLimit = res.data.items.indexingApi;
            this.checkIndexingLimit = res.data.items.searchConsoleApi;
          }
        })
        .catch((error) => {
          this.loadingProcess = false;

          console.log(error);
        });
    },
    getStatistics() {
      this.loadingProcess = true;
      indexingApi
        .getIndexingStatistics()
        .then((res) => {
          this.loadingProcess = false;
          if (res.success) {
            this.statistics = res.data.items;
          }
        })
        .catch((error) => {
          this.loadingProcess = false;

          console.log(error);
        });
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getList();
    },
    handleDelete(row) {
      this.deleteModal = true;
      this.deleteRow = row;
    },

    handleExport() {
      this.loadingProcess = true;
      const data = {
        indexed: false,
      };
      indexingApi
        .indexExport(data)
        .then((res) => {
          this.loadingProcess = false;
          if (res.success) {
            console.log(res);
            this.modalSuccess = true;
            if (res.data.link) {
              window.open(res.data.link, "_blank");
            }
          }
        })
        .catch((error) => {
          this.loadingProcess = false;

          console.log(error);
        });
    },

    handleCheck(url) {
      this.loadingProcess = true;
      if (url.length > 0) {
        const data = {
          pages: url.split(/\r\n|\r|\n/g),
        };
        indexingApi
          .checkIndexing(data)
          .then((res) => {
            this.loadingProcess = false;
            if (res.success) {
              this.modalSuccess = true;
              this.getList();
            }
          })
          .catch((error) => {
            this.loadingProcess = false;

            console.log(error);
          });
      }
    },
    handleAddToIndex(url) {
      this.loadingProcess = true;
      if (url.length > 0) {
        const data = {
          pages: url.split(/\r\n|\r|\n/g),
        };
        indexingApi
          .addToIndexing(data)
          .then((res) => {
            this.loadingProcess = false;
            if (res.success) {
              this.modalSuccess = true;
              this.getList();
              this.formDataToSend.add = "";
            }
          })
          .catch((error) => {
            this.loadingProcess = false;

            console.log(error);
          });
      }
    },
    handleAddIndex(url) {
      if (url.length > 0) {
        const data = {
          pages: [url + ""],
        };
        indexingApi
          .addToIndexing(data)
          .then((res) => {
            this.loadingProcess = false;
            if (res.success) {
              this.modalSuccess = true;
              this.getList();
              this.formDataToSend.check = "";
            }
          })
          .catch((error) => {
            this.loadingProcess = false;

            console.log(error);
          });
      }
    },
    handleSee(row) {
      this.seeModal = true;
      this.row = row;
    },
    handleCloseConfirmModal() {
      this.seeModal = false;
    },
    handleDeleteRow() {},
    handleCloseSuccessModal() {
      this.modalSuccess = false;
    },
    closeModal() {
      this.error = null;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.custom-table__header {
  grid-template-columns: 0.4fr 3fr 1fr 1fr 1fr 1fr 1fr !important;
}
/deep/.custom-table__row {
  grid-template-columns: 0.4fr 3fr 1fr 1fr 1fr 1fr 1fr !important;
}

.list {
  &__toggles {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }

  &__statistics {
    display: flex;
    flex-wrap: wrap;
  }

  &__statistic {
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 10px 20px;
    background: gainsboro;
    border-radius: 20px;
  }

  &__export {
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;

    /deep/.button-main {
      width: fit-content;
    }
  }

  &__limits {
    display: flex;
    justify-content: end;
    padding-bottom: 20px;
  }

  &__limit {
  }

  &__search {
    min-width: 250px;
    margin-right: 20px;
  }

  &__toggle {
    margin-right: 3px;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    background: $mainDisabled;
    color: $mainColor;
    cursor: pointer;
    display: flex;
    align-items: center;

    span {
      padding-left: 10px;
      font-size: 16px;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-radius: 0 7px 7px 0;
    }

    &:first-child {
      border-radius: 7px 0 0 7px;
    }

    &.active {
      background: $mainSecondColor;
      color: $g-color-white;
    }
  }
}
</style>
